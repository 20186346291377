import React, { useState } from 'react';
import { Button, FormGroup, TextField } from '@material-ui/core';
import { getRoute } from '../../components/Utilities';
import PublicPage from '../../components/PublicPage';
import '../../../sass/public/Public.scss';
import { sendMailResetPassword } from '../../redux/actions/auth';
import Logo from '../../../assets/image/british_council_english_white.svg';
import { connect } from 'react-redux';

const ForgotPassword = (props) => {
  const [isReset, setIsReset] = useState(false);
  const [value, setValue]= useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { dispatch } = props;

  const handleChange = (e) => {
    setValue(e.target.value);
  }
  const onSendMailResetPassword = (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    setErrorMessage('');
    dispatch(sendMailResetPassword(value)).then((response) => {
      if (response && response.success === true) {
        setIsReset(true);
      } else {
        setDisableSubmit(false);
        setErrorMessage(response.message);
      }
    });
  }
  const onReturnLogin = () => {
    const { history } = props;
    history.push(getRoute("ROOT"));
  }
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
  return (
    <PublicPage className="reset-password">
      <div className="login-header">
        <div className="my-class">
          <img src={Logo} alt="British Council" />
        </div>
        <div className="line-blue"></div>
        <div className='wrap-title'>
          <h5 className="login-header__title">Welcome to <br/>British Council English</h5>
          <p className="login-header__text">Enjoy your British Council English in-person course.</p>
        </div>
      </div>
      {!isReset ?
        <div className="form-login-block">
          <form onSubmit={onSendMailResetPassword} className="form" autoComplete="off">
            <div className="form-title">Password reset</div>
            <div className="line"></div>
            <div className="form-ders">Please enter the email address you used to set up your account. If you no longer have access to this email, please contact your nearest British Council office</div>
            <div className="form-title title2">Please enter your registered email address</div>
            <FormGroup noValidate autoComplete="off" className="form-login">
              <TextField
                  id={'login-email outlined-name'}
                  name={'email'}
                  onChange={handleChange}
                  className="form-login__input"
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                />
              {errorMessage && errorMessage.length > 0 ? <div className="error-message">{errorMessage}</div> : null}
              <div className="btn-block">
                <Button variant="contained" onClick={onReturnLogin} className="btn btn-return">Return to login</Button>
                <Button variant="contained" type="submit" className="btn btn-indigo" disabled={disableSubmit}>Reset password</Button>
              </div>
            </FormGroup>
          </form>
        </div> :
        <div className="form-login-block email-success">
          <form className="form">
            <div className="form-title">We’ve sent you an email.</div>
            <div className="line"></div>
            <div className="form-ders">Please follow the instructions in the email to reset your password and then try to log in again.</div>
            <FormGroup noValidate autoComplete="off" className="form-login">
              <div>
                <Button
                  variant="contained"
                  onClick={onReturnLogin}
                  className="btn btn-indigo"
                  style={{ marginLeft: 'auto', padding: '15px 0'}}
                >Go to log in</Button>
              </div>
            </FormGroup>
          </form>
        </div>
      }
    </PublicPage>
  )
}

export default connect()(ForgotPassword);