import { memo } from 'react';
import { Button } from '@material-ui/core';

import LocationTeacherDisplay from '../../Common/LocationTeacherDisplay';
import AvailabilityDisplay from '../../Common/AvailabilityDisplay';
import { windowOpen } from '../../../../components/Utilities';

import Users from '../../../../components/icon/Users';
import Star from '../../../../components/icon/Star';
import Level from '../../../../components/icon/Level/index';
import Workplace from '../../../../components/icon/Workplace';
import Check from '../../../../components/icon/Check';
import Key from '../../../../components/icon/Key';
import ReactHtmlParser from 'react-html-parser';
import useBookingPage from '../../../../hooks/BookingPage/useBookingPage';

import '../../../../../sass/private/CalendarPage/CalendarViewItem.scss';
import Layers from '../../../../components/icon/Layers';

const CalendarViewItem = (props) => {
  const {
    title,
    desc,
    time,
    active,
    borderColor,
    isNextClass,
    showStartIn,
    showJoinNow,
    startIn,
    joinNowText,
    isPastEndTime,
    onRedirectBookingWidow,
    lessonTimeId,
    joinLink,
    friendIcon,
    wishListIcon,
    levelsIcon,
    workPlaceIcon,
    teacher,
    ticked,
    isLocked,
    availabilityText,
    showAlertIcon,
    isFull,
    countDownStartIn,
    module,
    theme,
    themeBook
  } = props;


  const isBooked = (isNextClass || active) || false;

  let themeClass = "CalendarViewItem lesson-theme";

  if (isPastEndTime) {
    themeClass += "-past";
  }
  else if (isLocked) {
    themeClass += "-locked";
  }
  else if (isBooked) {
    themeClass += "-booked";
  }
  const cateColor = theme?.left_font_color;
  const cateBorderColor = theme?.product_category_color === '#23085A' ? '#fff' : 'transparent';
  const typeColor = theme?.right_font_color;
  return (
    <div className={`${isNextClass ? 'nextClass' : 'CalendarViewItem-listview'}`}>
      <div
        className={`${themeClass} ${props.classes ?? ''}`}
        style=
        {{ borderLeft: isNextClass ? `10px solid ${theme?.pathway_color}` : (isPastEndTime !== true && theme?.pathway_color && `10px solid ${theme.pathway_color}`),
        }}
      >
      <div className="view-row">
        <div className="view-left">
          <div className="text">
            <span className="product-category" style={{backgroundColor: theme?.product_category_color, color: cateColor, borderColor: cateBorderColor}}>{theme?.product_category_name}</span>
            <span className="product-type" style={{backgroundColor: theme?.product_type_color, color: typeColor}}>{theme?.product_type_name}</span>
          </div>
          {module && (
            <p className="module-title">
              {themeBook ? <div><Layers /> </div>: <div></div> }
              {module.title}
            </p>
          )}
          <div className="desc secondary">{ReactHtmlParser(desc)}</div>
          <div className="text">
            <LocationTeacherDisplay {...teacher} time={time} />
            <AvailabilityDisplay availabilityText={availabilityText} showAlertIcon={showAlertIcon} />
          </div>
        </div>
        <div className="view-center">
          <div className="icon-block secondary">
            {friendIcon && <Users />}
            {wishListIcon && <Star />}
            {levelsIcon && <Level code={levelsIcon} />}
            {workPlaceIcon && <Workplace />}
            {ticked && <Check />}
          </div>
        </div>
          <div className="view-right">
            {isPastEndTime !== true && <Button
              variant={"contained"}
              className={"btn btn-booked"}
              disabled={isFull ? true : false}
              onClick={() => onRedirectBookingWidow(lessonTimeId)}
            >{(isBooked || isLocked)? 'Details': 'Book'}</Button>}
            {showJoinNow && <Button variant="contained" className="btn btn-booked btn-join secondary" onClick={() => windowOpen(joinLink)}>{joinNowText}</Button>}
            {showStartIn && <p className="start-in">Starts in {isNextClass ? `${countDownStartIn}` : startIn}</p>}
          </div>
      </div>
      {isLocked &&
        <div className="lesson-locked-display secondary">
          <span className="text">Session locked for students.</span>
          <Key />
        </div>
      }
      </div>
    </div>
  )
}

export default memo(CalendarViewItem);