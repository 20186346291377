import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import CourseMapPage from "../../../components/CourseMapPage"
import useCourseMap from "../../../hooks/CourseMap/useCourseMap"
import CircularProgress from '../../private/Common/CircularProgress';
import { setCourseMapLevel, setZoneId } from "../../../redux/actions/CourseMap/CourseMapAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import '../../../../sass/public/CourseMap.scss';

const CourseMap = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentLevel, setCurrentLevel] = useState(0)
  const { levelList, openCourseMap, isLoading, zone } = useCourseMap()
  const { zoneId } = useParams()

  useEffect(() => {
    openCourseMap(zoneId)
  }, [])

  const onChooseLevel = (id, name) => {
    setCurrentLevel(id)
    dispatch(setZoneId(zone))
    dispatch(setCourseMapLevel(name))
  }
  
  const redirect = () => {
    const url = `/course-map/detail/${currentLevel}` + 
      ((zoneId && typeof zoneId !== undefined) ? `?zone_id=${zoneId}` : '')
    history.push(url)
  }
  
  const handleKeypress = (e, id, name) => {
    if(e.charCode == 13) {
      onChooseLevel(id, name);
    }
  }

  return (
    <CourseMapPage history={history}>
      <div className="course-map__wrapper home">
        <h1 className="course-map__title">Welcome to the course map</h1>
        <div className="course-map__content">
          <div className="course-map__description">
            <p>At the British Council you're in charge of what you learn.</p>
            <p>
              We know our students have specific goals and that one English language course does not suit everybody.
              That's why our course is designed so that you can choose your own pathway and personalise a course of study to meet your needs.
            </p>
            <p>
              This course map has information about the lessons and modules available at your teaching centre.
              Our academic and customer service teams will be more than happy to guide you, or you can navigate it yourself.
            </p>
            <p>Get started by choosing your level.</p>
          </div>
          <div className="course-map__level">
            <div className="course-map__level title">
              Choose your level
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 20V10M18 20V4M6 20v-4" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className="course-map__level options">
              {isLoading && <CircularProgress />}
              {
                !isLoading && levelList && levelList.length > 0 && levelList.map(item => {
                  return (
                    <div tabIndex={0} className={`course-map__level option-item ${item.id === currentLevel ? "selected" : ""}`} key={item.id}
                     onClick={() => onChooseLevel(item.id, item.name)}
                     onKeyPress={(e) => handleKeypress(e, item.id, item.name)}
                     >
                      {item.name}
                      {item.id === currentLevel && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 6.5L9 17.5L4 12.5" stroke="#00EDC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>}
                    </div>
                  )
                })
              }
              <button tabIndex={0} disabled={currentLevel === 0} onClick={redirect}>Confirm level</button>
            </div>
          </div>
        </div>
      </div>
    </CourseMapPage>
  )
}

export default CourseMap